import React from 'react';
import context from "./utils/Context";
import common from './utils/Common';

class ReactComponent extends React.Component{

    indexName;
    appName;
    graphQLEndpoint;
    urlServer;

    constructor(props){
        super(props);
        this.indexName = context.getIndexName(props);
        this.appName = context.getAppName();
        this.graphQLEndpoint = context.getGraphQLEndPoint();
        this.urlServer = context.getUrlSer();

    }


    getUrlEmbeded(url) {
        if (url !== ""){
            let cleanUrl = this.checkIsLanguageExist(url);

            if (this.props.sitecoreContext.site.isLanguageEmbedded === true)
                return "/" + this.props.sitecoreContext.language + cleanUrl;
            else
                return cleanUrl;
        }else{
            return "/";
        }
    }

    checkIsLanguageExist(url) {
        if (this.props.to !== undefined && url.includes("/"+ this.props.sitecoreContext.language +"/"))
            return url.substr(this.props.sitecoreContext.language.length + 1);
        else
            return url;
    }

    activeRatingStatusId(){
        return common.getActiveRatingStatusId(this.props);
    }

    globalRank(items){
        return common.getGlobalRank(items);     
    }

    maxPrice(){
        return common.getMaxPrice(this.props);
    }

}

export default ReactComponent;
