import React from 'react';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { DotLoader } from 'react-spinners';
import Moment from 'react-moment';
import ArticleCategoriesNavigation from '../ArticleCategoriesNavigation/index.js';
import i18next from 'i18next';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import { Query } from 'react-apollo';
import NavLinkAdv from '../../utils/NavLinkAdv';

const ArticlesNavigationQuery = gqlLoader('./query.graphql');

class ArticlesNavigation extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  render() {
    return (
      <>
        <div className="row_custom_small">
          <h1 className="col-md-12 text-center title__custom__articles">
            <Text field={this.props.fields.Heading} />{' '}
            <small>
              / <Text field={this.props.sitecoreContext.route.fields.articleTitle} />
            </small>
          </h1>
        </div>
        <div className="row_custom_small">
          <div className="col-md-12">
            <ArticleCategoriesNavigation />
          </div>
        </div>
        <div className="row_custom_small">
          <div className="col-md-12 text-left all-articles__custom row_custom_small">
            <Query
              query={ArticlesNavigationQuery}
              variables={{
                indexname: this.indexName,
                categoryId: this.props.sitecoreContext.route.itemId
                  .split('-')
                  .join('')
                  .toLowerCase(),
              }}
            >
              {({ loading, error, data }) => {
                if (loading)
                  return (
                    <div
                      className="sweet-loading"
                      style={{ marginTop: '50px', marginBottom: '50px' }}
                    >
                      <DotLoader
                        sizeUnit={'px'}
                        size={50}
                        color={'#003D0E'}
                        loading={!this.state.isLoaded}
                      />
                    </div>
                  );
                if (error) return <div>Error: {error.message}</div>;

                return data.product.results.items.map(function (article, idx) {
                  return (
                    <React.Fragment key={idx}>
                      <NavLinkAdv
                        to={article.item && article.item.url}
                        className="col-md-4 item__article__only"
                      >
                        <div
                          className="imgContainer"
                          style={{ backgroundImage: 'url(' + article.item.articleImage.src + ')' }}
                        ></div>
                        <small>
                          <Moment className="time_article" format="MMM DD, YYYY">
                            {article.item && article.item.date.value}
                          </Moment>
                        </small>
                        <Text field={article.item && article.item.articleTitle} tag="h2" />
                        <button className="btn btn-oro">{i18next.t('SeeMoreButton')}</button>
                      </NavLinkAdv>
                    </React.Fragment>
                  );
                });
              }}
            </Query>
          </div>
        </div>
      </>
    );
  }
}

export default withSitecoreContext()(ArticlesNavigation);
