import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactComponent from '../../ReactComponent';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

class NavLinkAdv extends ReactComponent {
    render() {
        if (this.props.to.startsWith('http') || this.props.to.startsWith('mailto')) {
            return (
                <a
                    href={this.props.to}
                    className={this.props.className}
                    key={this.props.key}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={this.props.style}
                    onClick={this.props.onClick}
                >
                    {this.props.children}
                </a>
            );
        } else {
            return (
                <NavLink
                    to={this.getUrlEmbeded(this.props.to)}
                    className={this.props.className}
                    key={this.props.key}
                    style={this.props.style}
                    onClick={this.props.onClick}
                >
                    {this.props.children}
                </NavLink>
            );
        }
    }
}

export default withSitecoreContext()(NavLinkAdv);