import React from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import Tagging from "./components/Tagging";
// THEME CSS
import './assets/css/elegant-icon.css';
import './assets/css/custom.css';

// SASS GLOBAL
import './assets/scss/global/responsive.scss';
import './assets/scss/global/styles.scss';
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route, location }) => {
  
  let pageTitle = route.fields.pageTitle ? route.fields.pageTitle : "";
  let wrapperClass = "wide-wrap";
  let paddingClass = "no-padding ";
  let headingContainerClass = "heading-container";
  for(var component1 of route.placeholders["jss-main"]) {
    if (component1.placeholders !== undefined && component1.componentName === "Post-LayoutWrapper") {
      wrapperClass = "wide-wrap page-layout-right-sidebar page-layout-border";
    }
    if (component1.placeholders !== undefined && component1.componentName === "ProductFinder-LayoutWrapper") {
      wrapperClass = "wide-wrap page-layout-left-sidebar page-layout-border";
    }
    if (component1.placeholders !== undefined && component1.componentName === "ProductLayoutWrapper") {
      wrapperClass = "wide-wrap page-layout-right-sidebar page-layout-border";
      paddingClass = " ";
    }
    if (component1.placeholders !== undefined && component1.componentName === "Article-LayoutWrapper") {
      wrapperClass = "wide-wrap page-layout-right-sidebar page-layout-border";
      paddingClass = " ";
    }
  }
  for(var component of route.placeholders["jss-heading"]) {
    if (component.componentName === "HeadingBanner") {
      paddingClass = " ";
      headingContainerClass = "heading-container heading-resize heading-no-button";
    }
    if (component.componentName === "HeadingSmallBanner") {
      paddingClass = " ";
      headingContainerClass = "heading-container";
    }
  }

  return (
      <React.Fragment>
          {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
          <Helmet>
              <title>
                  {(route.fields && route.fields.pageTitle && route.fields.pageTitle.value) ||
                      'Page'}
              </title>
              <meta
                  name="keywords"
                  content={
                      route.fields && route.fields.metaKeywords && route.fields.metaKeywords.value
                  }
              ></meta>
              <meta
                  name="description"
                  content={
                      route.fields &&
                      route.fields.metaDescription &&
                      route.fields.metaDescription.value
                  }
              ></meta>
              <link
                  rel="canonical"
                  href={typeof window !== 'undefined' ? window.location.href.toLowerCase() : ''}
              />
          </Helmet>

          {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
          <VisitorIdentification />

          {/* root placeholder for the app, which we add components to using route data */}
          {/* <div className="container">
        <Placeholder name="jss-main" rendering={route} />
      </div>
      </React.Fragment> */}
          {/* root placeholder for the app, which we add components to using route data */}
          <div id="wrapper" className={wrapperClass}>
              <header className="main-hd">
                  <Placeholder name="jss-header" rendering={route} />
              </header>

              <div className={headingContainerClass}>
                  <Placeholder name="jss-heading" rendering={route} />
              </div>
              <div
                  className={
                      'content-container ' +
                      paddingClass +
                      ((route.fields &&
                          route.fields.pageStyle &&
                          route.fields.pageStyle.fields.title &&
                          route.fields.pageStyle.fields.title.value) ||
                          '')
                  }
              >
                  <Placeholder name="jss-main" rendering={route} />
              </div>
              <Placeholder name="jss-footer" rendering={route} />
              <Tagging pageTitle={pageTitle} location={location} />
          </div>
      </React.Fragment>
  );
}

export default Layout;
