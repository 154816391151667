import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../NavLinkAdv';

class ProductPush extends React.Component {

  render() {
    return (
        <div className={'first-push'}>
            <NavLinkAdv to={this.props.url}>
                <Image field={this.props.image} className={'imgPush'} />
                <br />
                <Text field={this.props.title} tag={'span'} />
                <br />
                <div className="link">{this.props.label}</div>
                <br />
            </NavLinkAdv>
        </div>
    );
  }
}      

export default ProductPush;
