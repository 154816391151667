import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from "../../utils/NavLinkAdv";
import './BannerImageBackground.scss';
import { handleLinkClick } from '../Tagging';

const BannerImageBackground = (props) => {
    return (
        <div className={'BannerImageBackground ' + props.fields.designVariant.value}>
            <div
                className="img-ctn"
                style={{ backgroundImage: `url(` + props.fields.BackgroundImage.value.src + `)` }}
            >
                <div className="overlay" />
            </div>
            <div className="content-ctn">
                <h2 className="title">
                    <Text field={props.fields.Title} />
                </h2>
                <div className="description">
                    <Text field={props.fields.Description} />
                </div>
                {props.fields.Link && props.fields.Link.value.href && (
                    <div className="button btn">
                        <NavLinkAdv
                            to={props.fields.Link && props.fields.Link.value.href}
                            className="link"
                            onClick={() =>
                                handleLinkClick(
                                    props.fields.Link.value.text,
                                    props.fields.Link.value.href,
                                    props.sitecoreContext.route.fields.pageCategoryGTM.value
                                )
                            }
                        >
                            {props.fields.Link.value.text}
                        </NavLinkAdv>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withSitecoreContext()(BannerImageBackground);
