import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { DotLoader } from 'react-spinners';
import ArticleCategoriesNavigation from '../ArticleCategoriesNavigation/index.js';
import i18next from 'i18next';
import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import { Query } from 'react-apollo';
import NavLinkAdv from '../../utils/NavLinkAdv';
import ArticlePush from '../../utils/Common/ArticlePush/index.js';

const ArticlesNavigationQuery = gqlLoader('./query.graphql');
const CategoryNameQuery = gqlLoader('./categoryName.graphql');

class ArticlesNavigationAll extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      nbLoad: null,
      items: null,
      showButton: true,
    };
  }

  componentDidMount() {
    if (this.props.fields.ArticleReadMore.value !== '') {
      this.setState({
        nbLoad: parseInt(this.props.fields.ArticleReadMore.value),
      });
    }
  }

  loadMore() {
    if (this.state.nbLoad !== null) {
      this.setState({
        nbLoad: this.state.nbLoad + parseInt(this.props.fields.ArticleReadMore.value),
      });

      if (
        this.state.nbLoad >=
        this.state.items.length - parseInt(this.props.fields.ArticleReadMore.value)
      ) {
        this.setState({
          showButton: false,
        });
      }
    }
  }

  render() {
    let isAllCategories =
      this.props.sitecoreContext.route.fields.isAllCategories !== undefined &&
      this.props.sitecoreContext.route.fields.isAllCategories.value;

    let categoryId = isAllCategories
      ? '/sitecore/content/' + this.appName + '/'
      : this.props.sitecoreContext.route.itemId.split('-').join('').toLowerCase();

    let articleId =
      this.props.fields.ArticlePush &&
      this.props.fields.ArticlePush.id.split('-').join('').toLowerCase();
    let pageTitle = this.props.sitecoreContext.route.fields.pageTitle.value;

    return (
      <React.Fragment>
        {pageTitle !== 'Nos Ingrédients' && (
          <div className="max-content">
            <div className="catnav">
              <ArticleCategoriesNavigation />
            </div>
          </div>
        )}
        <div className="max-content resize">
          {articleId && (
            <Query
              query={CategoryNameQuery}
              variables={{
                indexname: this.indexName,
                rootpath: '/sitecore/content/' + this.appName + '/',
                id: articleId,
              }}
            >
              {({ loading, error, data }) => {
                if (loading)
                  return (
                    <div
                      className="sweet-loading"
                      style={{ marginTop: '50px', marginBottom: '50px' }}
                    >
                      <DotLoader
                        sizeUnit={'px'}
                        size={50}
                        color={'#003D0E'}
                        loading={!this.state.isLoaded}
                      />
                    </div>
                  );
                if (error) return <div>Error: {error.message}</div>;

                let image =
                  this.props.fields.ArticlePush &&
                  this.props.fields.ArticlePush.fields.articleImage;
                let title =
                  this.props.fields.ArticlePush &&
                  this.props.fields.ArticlePush.fields.articleTitle;
                let description =
                  this.props.fields.ArticlePush &&
                  this.props.fields.ArticlePush.fields.articleContent;
                let url = this.props.fields.ArticlePush && this.props.fields.ArticlePush.url;
                let label =
                  this.props.fields.ArticleButtonLabel &&
                  this.props.fields.ArticleButtonLabel.value;

                return data.articleCategory.results.items.map(function (articleCategory, index) {
                  return (
                    <ArticlePush
                      key={index}
                      image={image}
                      categoryName={articleCategory.item.parent.articleTitle}
                      title={title}
                      description={description}
                      url={url}
                      label={label}
                    />
                  );
                });
              }}
            </Query>
          )}
          <div className="col-12 text-left all-articles__custom other_article">
            <Query
              query={ArticlesNavigationQuery}
              variables={{ indexname: this.indexName, categoryId: categoryId }}
            >
              {({ loading, error, data }) => {
                if (loading)
                  return (
                    <div
                      className="sweet-loading"
                      style={{ marginTop: '50px', marginBottom: '50px' }}
                    >
                      <DotLoader
                        sizeUnit={'px'}
                        size={50}
                        color={'#003D0E'}
                        loading={!this.state.isLoaded}
                      />
                    </div>
                  );
                if (error) return <div>Error: {error.message}</div>;

                if (this.state.items === null) {
                  this.state.items = data.product.results.items;
                  //this.setState({items: data.product.results.items})
                }

                let dataToload = data.product.results.items;

                if (isAllCategories) {
                  dataToload = dataToload.filter(
                    (article) => article.item.parent.articleTitle.value.length > 0
                  );
                }

                if (!isAllCategories) {
                  dataToload = dataToload.sort(
                    (a, b) => a.item.categRanking.value - b.item.categRanking.value
                  );
                } else {
                  dataToload = dataToload.sort(
                    (a, b) => a.item.globalRanking.value - b.item.globalRanking.value
                  );
                }


                return (
                  dataToload &&
                  (this.state.nbLoad ? dataToload.slice(0, this.state.nbLoad) : dataToload).map(
                    function (article, idx) {
                      return (
                        <div key={idx} className={'subitems col-sm-12 col-md-6 col-lg-4 col-xl-4'}>
                          <NavLinkAdv
                            to={article.item && article.item.url}
                            className="item__article__only"
                          >
                            <div
                              className="imgContainer"
                              style={{
                                backgroundImage: 'url(' + article.item.articleImage.src + ')',
                              }}
                            />
                            <span className={'category'}>
                              {article.item.parent.articleTitle.value}
                            </span>
                            <Text field={article.item && article.item.articleTitle} tag="h2" />
                          </NavLinkAdv>
                          <NavLinkAdv
                            to={article.item && article.item.url}
                            className="btn btn-oro link"
                          >
                            {i18next.t('SeeMoreButton')}
                          </NavLinkAdv>
                        </div>
                      );
                    }
                  )
                );
              }}
            </Query>
          </div>
          {this.state.showButton && this.state.nbLoad && (
            <div className="text-center loadMore">
              <div className="btnWrapper">
                <a className={'btn_load_more'} onClick={() => this.loadMore()}>
                  {i18next.t('load-more-article')}
                </a>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withSitecoreContext()(ArticlesNavigationAll);
